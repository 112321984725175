<template>
  <div>
    <table class="yddviz-table">
      <caption>Andel som skrev enligt kön</caption>
      <thead>
        <tr>
          <th></th>
          <th scope="col" @click="sortBy = 'girls_share'" class="sort" :class="[ sortBy === 'girls_share' ? 'active' : '']"><img src="../assets/flickstudent.svg" class="icon" alt=""> Flickor</th>
          <th scope="col" @click="sortBy = 'boys_share'" class="sort" :class="[ sortBy === 'boys_share' ? 'active' : '']"><img src="../assets/pojkstudent.svg" class="icon" alt=""> Pojkar</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="subject in sortedSubjects" :key="subject.koe">
          <td>{{ subject.namn }}</td>
          <td :class="[ sortBy === 'girls_share' ? 'active' : '']">{{ subject.girls_share.toLocaleString('sv', { minimumFractionDigits: 1, style: 'percent' }) }}</td>
          <td :class="[ sortBy === 'boys_share' ? 'active' : '']">{{ subject.boys_share.toLocaleString('sv', { minimumFractionDigits: 1, style: 'percent' }) }}</td>
        </tr>
      </tbody>
    </table>
    <button v-if="!showAll" @click="showAll = true" class="yddviz-button">Visa fler ämnen</button>
  </div>
</template>

<script>
export default {
  name: 'SubjectsChart',
  components: {
  },
  data() {
    return {
      sortBy: 'girls_share',
      showAll: false,
    }
  },
  props: {
    data: Array,
  },
  computed: {
    sortedSubjects() {
      const max = this.showAll ? 20 : 10;
      const data = this.data.slice();
      data.sort((a, b) => {
        return (b[this.sortBy] - a[this.sortBy]);
      });
      return data.slice(0, max);
    },
  },
}
</script>
