<template>
  <div>
    <h2>Hälsokunskap minskade mest i popularitet – intresset för matematik större än nånsin</h2>
    <p>Hälsokunskap är det ämne som sjunkit mest i popularitet. Av årets examinander skrev 23,7 procent provet i hälsokunskap, vilket är cirka en tredjedel färre än för fem år sen.</p>
    <p>Det ämne som ökade mest i popularitet är lång matematik – numera är det fler som skriver lång än kort matematik. Samtidigt har också intresset för kort matematik ökat något. Bara en av sju examinander väljer att inte alls skriva matematik.</p>
    <p>Av realämnena har fysik ökat mest. I år skrev 25,5 procent av examinanderna provet i fysik.</p>
    <ul class="chart-legend">
      <li><span style="background: #307468"></span> Ökande trend</li>
      <li><span style="background: #ba413f"></span> Minskande trend</li>
    </ul>
    <div class="grid-container">
      <SmallChart v-for="(subject, index) in filteredSubjects" :data="subject" :key="index" :years="years" />
    </div>
    <button v-if="!showAll" @click="showAll = true" class="yddviz-button">Visa fler ämnen</button>
    <p v-else><em>Graferna är sorterade enligt studentprovens popularitet. Vi har bara tagit med de 20 populäraste ämnena.</em></p>
    <h2>Lång matematik fortfarande mest populärt bland killarna - tjejerna föredrar svenska och psykologi</h2>
    <p>Intresset för att skriva svenska bland de finskspråkiga gymnasisterna är klart könskorrelerat. Över hälften av flickorna skriver svenska men bara en fjärdedel av pojkarna gör det.</p>
    <p>Lång matematik är också betydligt populärare bland pojkar än bland flickor, liksom samhällslära, fysik, historia och geografi.</p>
    <p>Till de ämnesval som betydligt oftare föredras av flickor än av pojkar hör psykologi, biologi, hälsokunskap och religion.</p>
    <p>
      I tabellen kan du se hur intresset för olika prov skiljde sig mellan pojkar och flickor bland dem som utexaminerades våren {{ currentYear }}.
      Klicka på kolumnerna för att ändra sortering.
    </p>
    <SubjectsTable :data="data" />
  </div>
</template>

<script>
import data from '../data/subjects.json'

import SmallChart from './SmallChart'
import SubjectsTable from './SubjectsTable'

export default {
  name: 'SubjectsChart',
  components: {
    SmallChart,
    SubjectsTable
  },
  data() {
    return {
      data: data,
      showAll: false,
      currentYear: 2020,
    }
  },
  created() {
  },
  computed: {
    filteredSubjects() {
      // return this.data.filter(subject => subject.girls_count + subject.boys_count > 500);
      const highlights = ['M', 'N', 'TE', 'FY'];
      return this.showAll ? this.sortedSubjects : this.sortedSubjects.filter(data => highlights.includes(data.koe));
    },
    sortedSubjects() {
      const subjects = this.data.slice();
      const sortedSubjects = subjects.sort((a, b) => {
        return (b.shares[b.shares.length - 1] - a.shares[a.shares.length - 1]);
      });
      return sortedSubjects.slice(0, 20);
    },
    leastPopular() {
      return this.sortedSubjects.slice(0, 3);
    },
    mostPopular() {
      return this.sortedSubjects.slice(-3).reverse();
    },
    subjectName() {
      return this.selected ? this.selected.namn.toLowerCase() : null;
    },
    years() {
      const years = [];
      for (let i = 2015; i <= this.currentYear; i += 1) {
        years.push(i);
      }
      return years;
    },
  },
}
</script>

<style lang="scss">
.chart-legend {
  padding: 0;
  margin: 0 0 1em;
  font: 14px/16px Arial, sans-serif;
  line-height: 18px;
  color: #000;
  list-style-type: none;
  text-align: center;

  li {
    display: inline-block;
    margin-right: 12px;

    &:before {
      content: none;
    }
  }

  span {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 1;
  }
}
</style>
