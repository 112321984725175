import { createApp } from 'vue'
import App from './App.vue'

const appName = '2021-05-students_subjects';

const eventHandlers = {
  onMount: (name, element/*, services = {}*/) => {
    if (name !== appName) return;
    if (!element.querySelector(`#app-${appName}`)) return;

    const app = createApp(App)

    // parameters passed from the SYND shortcode can be accessed like this
    //const parameters = services.getParameters() || {};
    //app.provide('someParameter', parameters.someParameter || null)

    app.mount(`#app-${name}`)
  }
};

const yddvizAppMethods = {
  embedYlePlayer: function(elem, id, options) {
    window.ylePlayer.render({
      element: elem,
      props: {
        id: id,
        playFullScreen: !!options.playFullScreen,
      },
    });
  },
  getParameters: function() {
    const parameters = {};
    const searchParameters = new URLSearchParams(window.location.search);
    for (const [key, value] of searchParameters) {
      parameters[key] = value;
    }
    return parameters;
  },
};

if (process.env.NODE_ENV === 'production' && window.yleVisualisation) {
  // SYND OR FYND
  window.yleVisualisationEmbeds = window.yleVisualisationEmbeds || {};
  window.yleVisualisationEmbeds[appName] = eventHandlers;
}
else {
  eventHandlers.onMount(appName, document.body, yddvizAppMethods);
}