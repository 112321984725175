<template>
  <div>
    <h2>I Steinergymnasiet ökade medeltalet mest</h2>
    <p>Bland de svenskspråkiga gymnasierna var det i <em>Rudolf Steiner skolan i Helsingfors</em> som medeltalet ökade mest. Ifjol hade examinanderna medeltalet 4,0 medan vårens studenter hade 4,83 i de fyra obligatoriska ämnena.</p>
    <p>Välj ett annat gymnasium för att se medelvitsordet över tid och de populäraste ämnena. Gymnasier med färre än fem utexaminerade studenter finns av integritetsskäl inte med.</p>
    <select v-model="selected" class="yddviz-select">
      <option v-for="(school, index) in schools" :value="school" :key="index" :disabled="!school.student_count">{{ school.name }}</option>
    </select>
    <h3>Medelvitsord i de fyra obligatoriska ämnena</h3>
    <Highcharts :options="chartOptions" class="chart" />
    <p>Från {{ selected.name }} utexaminerades <strong>{{ selected.student_count }} studenter</strong> år {{ years[years.length - 1] }}. I tabellen kan du se de populäraste ämnena bland studenterna.</p>
    <table class="yddviz-table" v-if="selected.top5_subjects">
      <caption>De populäraste ämnena i {{ selected.name }}</caption>
      <thead>
        <tr>
          <th scope="col">Ämne</th>
          <th scope="col">Antal som skrev</th>
          <th scope="col">Andel som skrev</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="n in selected.top5_subjects.length" :key="n">
          <td>{{ subjectCodes[selected.top5_subjects[n - 1]].namn }}</td>
          <td>{{ selected.top5_counts[n - 1] }}</td>
          <td>{{ (selected.top5_counts[n - 1] / selected.student_count).toLocaleString('sv', { style: 'percent'}) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import { createHighcharts } from 'vue-highcharts';
import '../chartOptions.js'
import data from '../data/schools.json'
import yearlyMeans from '../data/yearly_means.json'
import subjectCodes from '../data/subject_codes.json'

export default {
  name: 'SchoolChart',
  components: {
    Highcharts: createHighcharts('Highcharts', Highcharts),
  },
  data() {
    return {
      selected: null,
      data,
      subjectCodes,
      yearlyMeans,
      years: [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020],
    }
  },
  created() {
    this.selected = this.schools[24];
  },
  methods: {
    makeSeries(valuesArray) {
      const series = this.years.map((year, index) => {
        return {
          x: year,
          y: valuesArray[index],
        }
      });
      return series;
    },
  },
  computed: {
    schools() {
      const schools = this.data.slice();
      schools.splice(36, 0, {
        name: '──────────',
      });
      return schools;
    },
    schoolSeries() {
      const series = this.years.map((year, index) => {
        return {
          x: year,
          y: this.selected.grades_list[index],
        }
      });
      return series;
    },
    chartOptions() {
      return {
        chart: {
          height: 340,
          type: 'line',
        },
        title: null,
        subtitle: false,
        credits: {
          enabled: false,
        },
        // legend: false,
        series: [{
          name: this.selected.name,
          data: this.makeSeries(this.selected.grades_list),
          zIndex: 2,
        }, {
          name: 'Svenskspråkiga skolor',
          data: this.makeSeries(this.yearlyMeans.sv),
          color: 'rgba(255, 220, 0, 0.6)',
        },
        {
          name: 'Finskspråkiga skolor',
          data: this.makeSeries(this.yearlyMeans.fi),
          color: 'rgba(79, 178, 196, 0.6)',
        }],
        xAxis: {
          type: 'linear',
          gridLineWidth: 1,
          tickmarkPlacement: 'on',
          tickInterval: 1,
          title: {
            enabled: false,
          },
        },
        yAxis: {
          title: false,
          min: 1,
          max: 7,
        },
      }
    },
  },
}
</script>
