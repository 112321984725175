<template>
  <SubjectsChart />
  <img src="./assets/ros-ornament.svg" alt="En ros" class="rosor">
  <SchoolChart />
</template>

<script>
import SubjectsChart from './components/SubjectsChart'
import SchoolChart from './components/SchoolChart'

export default {
  name: 'App',
  components: {
    SubjectsChart,
    SchoolChart,
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss">
@media screen and (max-width: 499px) {
  .ydd-article__body #app-2021-05-students_subjects {
    margin-left: -20px;
    margin-right: -20px;
  }
}

#app-2021-05-students_subjects {
  font-family: 'Open Sans', Arial, sans-serif;
  color: #000;
  margin: 0 auto;
  max-width: 560px;
  background: #fafafa;
  padding: 10px 15px;
  box-sizing: border-box;

  p {
    font-size: 16px;

    @media screen and (min-width: 480px) {
      font-size: 18px;
    }
  }

  img {
    max-width: 100%;
  }

  .rosor {
    margin: 0 auto;
    display: block;
  }

  h2 {
    text-align: center;
    font-weight: bold;
    font-family: 'Yle', Arial, sans-serif;
    margin-top: 1em;

    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
    font-family: 'Yle', Arial, sans-serif;
  }

  .filters {
    text-align: center;
    margin-bottom: .5em;

    label {
      font-size: 1em;
      margin-bottom: .2em;
      display: block;
    }
  }

  .chart {
    margin-bottom: 2em;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1em;
  }

  .icon {
    height: 26px;
    vertical-align: bottom;
  }
}

.yddviz-button {
  text-decoration: none;
  background: #fff;
  color: #444;
  font-family: 'Open Sans', Arial, sans-serif;
  border-radius: 4px;
  border: 2px solid #444;
  font-size: 1em;
  padding: 8px 12px;
  border-radius: 4px;
  display: block;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
  cursor: pointer;
  margin: 0 auto;
  line-height: 1;
  transition: background-color .5s;

  &:hover {
    background-color: #444;
    color: white;
  }

  &:focus {
    outline: none;
    text-decoration: none;
    border: 2px dashed #444;
  }
}

.yddviz-select {
  display: block;
  margin: 0 auto 1em;
  border: 2px solid #333;
  border-radius: 6px;
  padding: 6px;
  width: 100%;
  font-size: 16px;
  line-height: 1.5;

  &:focus {
    outline: none;
    border: 2px dashed #333;
    background: #f5f5f5;
  }
}

.yddviz-table {
  border-collapse: collapse;
  font-size: 16px !important;
  width: 100% !important;
  margin-bottom: 1em !important;
  margin-top: 1em !important;
  word-break: initial;

  @media screen and (min-width: 480px) {
    font-size: 18px !important;
  }

  caption {
    font-size: 16px;
    font-family: 'Open Sans', Arial, sans-serif;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1em;
    padding: 0 !important;
  }

  th, td {
    padding: 8px 6px !important;
    border: none;
  }

  tr:nth-child(even) {
    td, th {
      background: #f5f5f5 !important;

      &.active {
        background: #daebeb !important;
      }
    }
  }

  tr:nth-child(odd) {
    td, th {
      background: #fff !important;

      &.active {
        background: #f5f5ff !important;
      }
    }
  }

  tr td {
    text-align: right;

    &:first-child {
      text-align: left;
    }
  }

  thead {
    tr th {
      background-color: #fff !important;
      color: #000 !important;
      font-weight: bold;
      text-align: center;
      vertical-align: top;
      border-bottom: 1px solid #999;
      text-transform: none !important;
      white-space: normal;
      text-align: right;
      font-size: 14px;

      &:first-child {
        text-align: left;
      }
      
      &.sort {
        cursor: pointer;
        text-align: center;

        &.active {
          background: #daebeb !important;
        
          &:after {
            content: ' \25BC';
          }
        }
      }
    }
  }

  tbody tr th {
    font-weight: normal;
    text-align: left;
  }
}
</style>
