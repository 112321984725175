import Highcharts from 'highcharts'

Highcharts.setOptions({
  colors: ['#0a284b', '#ec5b60', '#4fb2c4', '#3d76ca', '#f1924a', '#f9d358'],
  lang: {
    numericSymbols: null,
    weekdays: ["söndag", "måndag", "tisdag", "onsdag", "torsdag", "fredag", "lördag"],
    months: ["januari", "februari", "mars", "april", "maj", "juni", "juli", "augusti", "september", "oktober", "november", "december"],
    shortMonths: ["jan", "febr", "mar", "apr", "maj", "jun", "jul", "aug", "sept", "okt", "nov", "dec"],
    thousandsSep: " ",
    decimalPoint: ",",
    contextButtonTitle: "Skriv ut/ladda ner",
    printChart: "Skriv ut",
    downloadPNG: "PNG-bild",
    downloadJPEG: "JPEG-bild",
    downloadPDF: "PDF-fil",
    downloadSVG: "SVG-fil",
    resetZoom: "Nollställ zoom",
    resetZoomTitle: "Nollställ zoom"
  },
  credits: false,
  chart: {
    spacing: [10, 0, 0, 0],
    style: {
      fontFamily: ['Arial', 'sans-serif'],
    },
    backgroundColor: 'transparent',
  },
  title: {
    align: 'left',
    style: {
      color: '#000',
      fontSize: '22px',
      fontWeight: 'bold',
    },
  },
  subtitle: {
    align: 'left',
    style: {
      color: '#000',
      fontSize: '16px',
      fontWeight: 'normal',
    },
  },
  legend: {
    floating: true,
    align: 'left',
    verticalAlign: 'top',
    x: 60,
    symbolRadius: 0,
    itemStyle: {
      fontWeight: 'normal',
    },
  },
  plotOptions: {
    line: {
      lineWidth: 4,
    },
    pie: {
      innerSize: '50%',
      dataLabels: {
        connectorPadding: 0,
        connectorShape: 'crookedLine',
        crookDistance: '70%',
        distance: 10,
        style: {
          fontWeight: 'normal',
          fontSize: '14px',
        },
        softConnector: false,
      }
    },
  },
  tooltip: {
    backgroundColor: 'rgba(255,255,255,0.9)',
    shadow: false,
  },
  xAxis: {
    dateTimeLabelFormats: {
      millisecond: '%H:%M:%S.%L',
      second: '%H:%M:%S',
      minute: '%H:%M',
      hour: '%H:%M',
      day: '%e %b',
      week: '%e %b',
      month: '%b \'%y',
      year: '%Y',
    },
    lineColor: '#000',
    labels: {
      autoRotationLimit: 40,
      padding: 2,
      style: {
        fontSize: '14px',
        color: '#000',
      },
    },
    tickLength: 5,
    title: {
      style: {
        fontSize: '14px',
      },
    },
  },
  yAxis: {
    gridLineColor: '#E6E6E6',
    labels: {
      autoRotationLimit: 40,
      padding: 2,
      style: {
        fontSize: '12px',
        color: '#000',
      },
    },
    title: {
      style: {
        fontSize: '14px',
        color: '#000',
      },
    },
  },
});