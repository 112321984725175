<template>
  <div class="column">
    <Highcharts :options="chartOptions" class="chart" />
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import { createHighcharts } from 'vue-highcharts';

export default {
  name: 'SmallChart',
  components: {
    Highcharts: createHighcharts('Highcharts', Highcharts),
  },
  props: {
    data: Object,
    years: Array,
  },
  data() {
    return {
    }
  },
  computed: {
    series() {
      const series = this.years.map((year, index) => {
        return {
          x: year,
          y: this.data.shares[index],
        }
      });

      return series;
    },
    chartOptions() {
      return {
        chart: {
          height: 160,
          type: 'line',
        },
        title: {
          text: this.data.namn,
          style: {
            color: '#000',
            fontSize: '12px',
          },
        },
        subtitle: false,
        credits: {
          enabled: false,
        },
        tooltip: {
          valueSuffix: ' %',
        },
        legend: false,
        series: [{
          name: 'Andel som skrev',
          data: this.series,
          color: this.series[this.series.length - 1].y > this.series[0].y ? '#307468' : '#ba413f',
        }],
        xAxis: {
          type: 'linear',
          // gridLineWidth: 1,
          tickmarkPlacement: 'on',
          //tickInterval: 1,
          title: {
            enabled: false,
          },
        },
        yAxis: {
          title: false,
          labels: {format: '{value} %'},
          max: this.series[this.series.length - 1].y > 50 ? 100 : 50,
          min: 0,
        },
      }
    },
  },
}
</script>
